import React, {Component} from "react";
import withLocation from "./with-location";
import {connect} from "react-redux";
import {formatQuery} from "../../controllers/utilities/string-utils";

class AndroidSDK extends Component {
    constructor(props, context) {
        super(props, context);
        const {gameId, attributionId, transactionId} = formatQuery(props.location.search);
        if(gameId) {
            this.props.setGameId(gameId);
        }
        if(attributionId) {
            this.props.setAttributionId(attributionId);
        } else {
            this.props.setAttributionId(`0`);
        }
        if(transactionId) {
            this.props.setTransactionId(transactionId);
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.props.children}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        sdkSettings: state.sdkSettings
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setGameId: (gameId) => {
            dispatch({
                type: "SET_GAME_ID",
                value: gameId
            });
        },
        setAttributionId: (attributionId) => {
            dispatch({
                type: "SET_ATTRIBUTION_ID",
                value: attributionId
            });
        },
        setTransactionId: (transactionId) => {
            dispatch({
                type: "SET_TRANSACTION_ID",
                value: transactionId
            });
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withLocation(AndroidSDK));