import {combineReducers} from "redux";
import sessionReducer from "./session-reducer";
import sdkSettingsReducer from "./sdk-settings-reducer";
import languageSettingsReducer from "./languageSettingsReducer";

export default function combine() {
    return combineReducers({
        session: sessionReducer,
        sdkSettings: sdkSettingsReducer,
        languageSettings: languageSettingsReducer
    });
}