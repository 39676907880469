import React, { Component } from "react";
import i18next from "./config";
import { I18nextProvider, withTranslation } from "react-i18next";

export default function withTrans(WrappedComponent) {
    WrappedComponent = withTranslation()(WrappedComponent);
    let lang = "";
    if (typeof navigator !== `undefined`) {
        lang = navigator.language;
    }
    if (lang.indexOf("en") !== -1){
        lang = "en";
    }else if (lang.indexOf("JP") !== -1){
        lang = "jp";
    }else{
        lang = "ch";
    }
    // 自动识别语言包
    // lang = "en";
    // if(typeof window !== `undefined`) {
    //     localStorage.setItem("LANGUAGE", lang);
    // }    
    return class extends Component {
        render() {
            return (
                <I18nextProvider i18n={i18next}>
                    <WrappedComponent {...this.props} language={i18next.language} />
                </I18nextProvider>
            );
        }
    };
}