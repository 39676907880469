const persistentLangKey = "LANGUAGE";
const handler = {
    SET_LANG: (o, n) => {
        localStorage.setItem(persistentLangKey, n);
        return Object.assign({}, o, {currentLang: n});

    },
    LOAD_LANG: (o, {success, failure}) => {
        try {
            let cachedLang = localStorage[persistentLangKey];
            if(!cachedLang) {
                failure();
                return null;
            }
            success(cachedLang);
            return Object.assign({}, {currentLang: cachedLang});
        } catch(e) {
            failure();
            return null;
        }
    }
};

export default function languageSettingsReducer(settings = null, action) {
    if(handler[action.type]) {
        return handler[action.type](settings, action.value);
    }
    return settings;
}
