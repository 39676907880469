import React, {Component} from "react";
import {connect} from "react-redux";
import {formatQuery} from "../../controllers/utilities/string-utils";
import withLocation from "./with-location";

class Session extends Component {
    constructor(props, context) {
        super(props, context);
        const {accessToken} = formatQuery(props.location.search);
        if(accessToken) {
            this.props.setSession({accessToken});
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.props.children}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        session: state.session
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setSession: (session) => {
            dispatch({
                type: "SET_SESSION",
                value: session
            });
        }
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withLocation(Session));