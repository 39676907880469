import React from "react";
import {Location} from "@reach/router";

export default function withLocation(ComponentToWrap) {
    return props => (
        <Location>
            {({location, navigate}) => (
                <ComponentToWrap
                    {...props}
                    location={location}
                    navigate={navigate}
                />
            )}
        </Location>
    );
}