import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import withTrans from "../../i18n/withTrans";

class Language extends Component {
    constructor(props, context) {
        super(props, context);
        const {i18n} = props;
        this.props.loadLang(cachedLang => {
            i18n.changeLanguage(cachedLang);
        }, () => {
            i18n.changeLanguage("ch");
        });
    }

    render() {
        return (
            <Fragment>
                {this.props.children}
            </Fragment>
        );
    }
}

function mapStateRoProps(state) {
    return {
        languageSettings: state.languageSettings
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setLang: lang => {
            dispatch({
                type: "SET_LANG",
                value: lang
            });
        },
        loadLang: (success, failure) => {
            dispatch({
                type: "LOAD_LANG",
                value: {success, failure}
            });
        }
    };
}

export default connect(mapStateRoProps, mapDispatchToProps)(withTrans(Language));