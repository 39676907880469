export class Matcher {
    static isEmail(email) {
        let reg = new RegExp("^(([^<>()\\[\\]\\\\.,;:\\s@\"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@\"]+)*)|(\".+\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$");
        return reg.test(email);
    }
}

export function formatQuery(queryString) {
    if(queryString.startsWith("?"))
        queryString = queryString.substring(1);
    const list = queryString.split("&");
    const result = {};
    list.forEach(item => {
        const pairs = item.split("=");
        if(pairs.length === 2) {
            result[pairs[0]] = pairs[1];
        }
    });
    return result;
}