import React from "react";
import {Provider} from "react-redux";
import createStore from "./src/state/createStore";
import Session from "./src/components/wrappers/session";
import AndroidSDK from "./src/components/wrappers/android-sdk";
import Language from "./src/components/wrappers/language";

export default function wrapWithProvider({element}) {
    const store = createStore();
    return (
        <Provider store={store}>
            <Session>
                <Language>
                    <AndroidSDK>
                        {element}
                    </AndroidSDK>
                </Language>
            </Session>
        </Provider>
    );
}