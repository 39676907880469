const persistentSessionKey = "SESSION";
const handler = {
    SET_SESSION: (o, n) => {
        localStorage.setItem(persistentSessionKey, JSON.stringify(n));
        return Object.assign({}, n);
    }
};

export default function sessionReducer(session = null, action) {
    if(handler[action.type]) {
        return handler[action.type](session, action.value);
    }
    return session;
}
