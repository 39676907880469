// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-configs-page-1-js": () => import("./../src/pages/account-configs-page1.js" /* webpackChunkName: "component---src-pages-account-configs-page-1-js" */),
  "component---src-pages-account-configs-page-2-js": () => import("./../src/pages/account-configs-page2.js" /* webpackChunkName: "component---src-pages-account-configs-page-2-js" */),
  "component---src-pages-attach-email-js": () => import("./../src/pages/attach-email.js" /* webpackChunkName: "component---src-pages-attach-email-js" */),
  "component---src-pages-auth-2-j-4-js": () => import("./../src/pages/auth2J4.js" /* webpackChunkName: "component---src-pages-auth-2-j-4-js" */),
  "component---src-pages-chatroom-js": () => import("./../src/pages/chatroom.js" /* webpackChunkName: "component---src-pages-chatroom-js" */),
  "component---src-pages-confirm-purchase-js": () => import("./../src/pages/confirm-purchase.js" /* webpackChunkName: "component---src-pages-confirm-purchase-js" */),
  "component---src-pages-credit-packages-js": () => import("./../src/pages/credit-packages.js" /* webpackChunkName: "component---src-pages-credit-packages-js" */),
  "component---src-pages-credit-purchase-ok-result-js": () => import("./../src/pages/credit-purchase-ok-result.js" /* webpackChunkName: "component---src-pages-credit-purchase-ok-result-js" */),
  "component---src-pages-credit-purchase-ok-result-2-js": () => import("./../src/pages/credit-purchase-ok-result2.js" /* webpackChunkName: "component---src-pages-credit-purchase-ok-result-2-js" */),
  "component---src-pages-credit-purchase-result-js": () => import("./../src/pages/credit-purchase-result.js" /* webpackChunkName: "component---src-pages-credit-purchase-result-js" */),
  "component---src-pages-credit-purchase-result-2-js": () => import("./../src/pages/credit-purchase-result2.js" /* webpackChunkName: "component---src-pages-credit-purchase-result-2-js" */),
  "component---src-pages-downloadimage-js": () => import("./../src/pages/downloadimage.js" /* webpackChunkName: "component---src-pages-downloadimage-js" */),
  "component---src-pages-downloadimage-2-js": () => import("./../src/pages/downloadimage2.js" /* webpackChunkName: "component---src-pages-downloadimage-2-js" */),
  "component---src-pages-game-support-att-js": () => import("./../src/pages/game-support-att.js" /* webpackChunkName: "component---src-pages-game-support-att-js" */),
  "component---src-pages-game-support-js": () => import("./../src/pages/game-support.js" /* webpackChunkName: "component---src-pages-game-support-js" */),
  "component---src-pages-game-support-1-js": () => import("./../src/pages/game-support1.js" /* webpackChunkName: "component---src-pages-game-support-1-js" */),
  "component---src-pages-game-support-2-js": () => import("./../src/pages/game-support2.js" /* webpackChunkName: "component---src-pages-game-support-2-js" */),
  "component---src-pages-gotoad-return-purchase-js": () => import("./../src/pages/gotoad-return-purchase.js" /* webpackChunkName: "component---src-pages-gotoad-return-purchase-js" */),
  "component---src-pages-gotoad-return-result-js": () => import("./../src/pages/gotoad-return-result.js" /* webpackChunkName: "component---src-pages-gotoad-return-result-js" */),
  "component---src-pages-gotoad-js": () => import("./../src/pages/gotoad.js" /* webpackChunkName: "component---src-pages-gotoad-js" */),
  "component---src-pages-gotoadios-js": () => import("./../src/pages/gotoadios.js" /* webpackChunkName: "component---src-pages-gotoadios-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-last-report-js": () => import("./../src/pages/last-report.js" /* webpackChunkName: "component---src-pages-last-report-js" */),
  "component---src-pages-login-js": () => import("./../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-message-js": () => import("./../src/pages/message.js" /* webpackChunkName: "component---src-pages-message-js" */),
  "component---src-pages-myreportreturnconfirm-js": () => import("./../src/pages/myreportreturnconfirm.js" /* webpackChunkName: "component---src-pages-myreportreturnconfirm-js" */),
  "component---src-pages-myreportreturnsdkcallback-js": () => import("./../src/pages/myreportreturnsdkcallback.js" /* webpackChunkName: "component---src-pages-myreportreturnsdkcallback-js" */),
  "component---src-pages-payment-providers-js": () => import("./../src/pages/payment-providers.js" /* webpackChunkName: "component---src-pages-payment-providers-js" */),
  "component---src-pages-payment-support-addticket-js": () => import("./../src/pages/payment-support-addticket.js" /* webpackChunkName: "component---src-pages-payment-support-addticket-js" */),
  "component---src-pages-payment-support-addticket-2-js": () => import("./../src/pages/payment-support-addticket2.js" /* webpackChunkName: "component---src-pages-payment-support-addticket-2-js" */),
  "component---src-pages-payment-support-result-js": () => import("./../src/pages/payment-support-result.js" /* webpackChunkName: "component---src-pages-payment-support-result-js" */),
  "component---src-pages-payment-support-js": () => import("./../src/pages/payment-support.js" /* webpackChunkName: "component---src-pages-payment-support-js" */),
  "component---src-pages-react-detect-device-test-js": () => import("./../src/pages/react-detect-device-test.js" /* webpackChunkName: "component---src-pages-react-detect-device-test-js" */),
  "component---src-pages-reset-password-js": () => import("./../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-reset-password-1-js": () => import("./../src/pages/reset-password1.js" /* webpackChunkName: "component---src-pages-reset-password-1-js" */),
  "component---src-pages-reset-password-2-js": () => import("./../src/pages/reset-password2.js" /* webpackChunkName: "component---src-pages-reset-password-2-js" */),
  "component---src-pages-sdk-ad-iframe-js": () => import("./../src/pages/sdk-ad-iframe.js" /* webpackChunkName: "component---src-pages-sdk-ad-iframe-js" */),
  "component---src-pages-sdk-ad-ios-iframe-js": () => import("./../src/pages/sdk-ad-ios-iframe.js" /* webpackChunkName: "component---src-pages-sdk-ad-ios-iframe-js" */),
  "component---src-pages-sdk-ad-ios-js": () => import("./../src/pages/sdk-ad-ios.js" /* webpackChunkName: "component---src-pages-sdk-ad-ios-js" */),
  "component---src-pages-sdk-ad-noodle-bc-js": () => import("./../src/pages/sdk-ad-noodle-bc.js" /* webpackChunkName: "component---src-pages-sdk-ad-noodle-bc-js" */),
  "component---src-pages-sdk-ad-noodle-bc-2-js": () => import("./../src/pages/sdk-ad-noodle-bc2.js" /* webpackChunkName: "component---src-pages-sdk-ad-noodle-bc-2-js" */),
  "component---src-pages-sdk-ad-noodle-entr-js": () => import("./../src/pages/sdk-ad-noodle-entr.js" /* webpackChunkName: "component---src-pages-sdk-ad-noodle-entr-js" */),
  "component---src-pages-sdk-ad-noodle-entr-2-js": () => import("./../src/pages/sdk-ad-noodle-entr2.js" /* webpackChunkName: "component---src-pages-sdk-ad-noodle-entr-2-js" */),
  "component---src-pages-sdk-ad-noodle-entr-2-only-js": () => import("./../src/pages/sdk-ad-noodle-entr2only.js" /* webpackChunkName: "component---src-pages-sdk-ad-noodle-entr-2-only-js" */),
  "component---src-pages-sdk-ad-noodle-entronly-js": () => import("./../src/pages/sdk-ad-noodle-entronly.js" /* webpackChunkName: "component---src-pages-sdk-ad-noodle-entronly-js" */),
  "component---src-pages-sdk-ad-noodle-fr-js": () => import("./../src/pages/sdk-ad-noodle-fr.js" /* webpackChunkName: "component---src-pages-sdk-ad-noodle-fr-js" */),
  "component---src-pages-sdk-ad-noodle-fr-2-js": () => import("./../src/pages/sdk-ad-noodle-fr2.js" /* webpackChunkName: "component---src-pages-sdk-ad-noodle-fr-2-js" */),
  "component---src-pages-sdk-ad-noodle-j-4-js": () => import("./../src/pages/sdk-ad-noodle-j4.js" /* webpackChunkName: "component---src-pages-sdk-ad-noodle-j-4-js" */),
  "component---src-pages-sdk-ad-noodle-nu-js": () => import("./../src/pages/sdk-ad-noodle-nu.js" /* webpackChunkName: "component---src-pages-sdk-ad-noodle-nu-js" */),
  "component---src-pages-sdk-ad-noodle-nu-2-js": () => import("./../src/pages/sdk-ad-noodle-nu2.js" /* webpackChunkName: "component---src-pages-sdk-ad-noodle-nu-2-js" */),
  "component---src-pages-sdk-ad-noodle-nw-js": () => import("./../src/pages/sdk-ad-noodle-nw.js" /* webpackChunkName: "component---src-pages-sdk-ad-noodle-nw-js" */),
  "component---src-pages-sdk-ad-noodle-nw-2-js": () => import("./../src/pages/sdk-ad-noodle-nw2.js" /* webpackChunkName: "component---src-pages-sdk-ad-noodle-nw-2-js" */),
  "component---src-pages-sdk-ad-noodle-unionpay-js": () => import("./../src/pages/sdk-ad-noodle-unionpay.js" /* webpackChunkName: "component---src-pages-sdk-ad-noodle-unionpay-js" */),
  "component---src-pages-sdk-ad-noodle-unionpay-2-js": () => import("./../src/pages/sdk-ad-noodle-unionpay2.js" /* webpackChunkName: "component---src-pages-sdk-ad-noodle-unionpay-2-js" */),
  "component---src-pages-sdk-ad-noodle-vip-js": () => import("./../src/pages/sdk-ad-noodle-vip.js" /* webpackChunkName: "component---src-pages-sdk-ad-noodle-vip-js" */),
  "component---src-pages-sdk-ad-noodle-vip-2-js": () => import("./../src/pages/sdk-ad-noodle-vip2.js" /* webpackChunkName: "component---src-pages-sdk-ad-noodle-vip-2-js" */),
  "component---src-pages-sdk-ad-noodle-js": () => import("./../src/pages/sdk-ad-noodle.js" /* webpackChunkName: "component---src-pages-sdk-ad-noodle-js" */),
  "component---src-pages-sdk-ad-js": () => import("./../src/pages/sdk-ad.js" /* webpackChunkName: "component---src-pages-sdk-ad-js" */),
  "component---src-pages-sdk-ad-2-js": () => import("./../src/pages/sdk-ad2.js" /* webpackChunkName: "component---src-pages-sdk-ad-2-js" */),
  "component---src-pages-sdk-callback-js": () => import("./../src/pages/sdk-callback.js" /* webpackChunkName: "component---src-pages-sdk-callback-js" */),
  "component---src-pages-sdk-callback-2-js": () => import("./../src/pages/sdk-callback2.js" /* webpackChunkName: "component---src-pages-sdk-callback-2-js" */),
  "component---src-pages-sdk-teaching-js": () => import("./../src/pages/sdk-teaching.js" /* webpackChunkName: "component---src-pages-sdk-teaching-js" */),
  "component---src-pages-sdk-teaching-1-js": () => import("./../src/pages/sdk-teaching1.js" /* webpackChunkName: "component---src-pages-sdk-teaching-1-js" */),
  "component---src-pages-sdk-teaching-2-js": () => import("./../src/pages/sdk-teaching2.js" /* webpackChunkName: "component---src-pages-sdk-teaching-2-js" */),
  "component---src-pages-sdk-teaching-3-js": () => import("./../src/pages/sdk-teaching3.js" /* webpackChunkName: "component---src-pages-sdk-teaching-3-js" */),
  "component---src-pages-sdk-teaching-4-js": () => import("./../src/pages/sdk-teaching4.js" /* webpackChunkName: "component---src-pages-sdk-teaching-4-js" */),
  "component---src-pages-sdk-teachingsdkcallback-js": () => import("./../src/pages/sdk-teachingsdkcallback.js" /* webpackChunkName: "component---src-pages-sdk-teachingsdkcallback-js" */),
  "component---src-pages-signup-js": () => import("./../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-unionpay-page-1-js": () => import("./../src/pages/unionpay-page1.js" /* webpackChunkName: "component---src-pages-unionpay-page-1-js" */),
  "component---src-pages-unionpay-page-2-js": () => import("./../src/pages/unionpay-page2.js" /* webpackChunkName: "component---src-pages-unionpay-page-2-js" */),
  "component---src-pages-x-recharge-page-0-js": () => import("./../src/pages/x-recharge-page0.js" /* webpackChunkName: "component---src-pages-x-recharge-page-0-js" */),
  "component---src-pages-x-recharge-page-1-js": () => import("./../src/pages/x-recharge-page1.js" /* webpackChunkName: "component---src-pages-x-recharge-page-1-js" */),
  "component---src-pages-x-recharge-page-3-js": () => import("./../src/pages/x-recharge-page3.js" /* webpackChunkName: "component---src-pages-x-recharge-page-3-js" */),
  "component---src-pages-xr-saleing-page-1-js": () => import("./../src/pages/xr-saleing-page1.js" /* webpackChunkName: "component---src-pages-xr-saleing-page-1-js" */),
  "component---src-pages-xr-saleing-page-2-js": () => import("./../src/pages/xr-saleing-page2.js" /* webpackChunkName: "component---src-pages-xr-saleing-page-2-js" */),
  "component---src-pages-xr-saleing-page-3-2-js": () => import("./../src/pages/xr-saleing-page3-2.js" /* webpackChunkName: "component---src-pages-xr-saleing-page-3-2-js" */),
  "component---src-pages-xr-saleing-page-3-js": () => import("./../src/pages/xr-saleing-page3.js" /* webpackChunkName: "component---src-pages-xr-saleing-page-3-js" */),
  "component---src-pages-xr-saleing-page-4-js": () => import("./../src/pages/xr-saleing-page4.js" /* webpackChunkName: "component---src-pages-xr-saleing-page-4-js" */)
}

