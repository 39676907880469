const handler = {
    SET_GAME_ID: (o, n) => {
        return Object.assign({}, o, {gameId: n});
    },
    SET_ATTRIBUTION_ID: (o, n) => {
        return Object.assign({}, o, {attributionId: n});
    },
    SET_TRANSACTION_ID: (o, n) => {
        return Object.assign({}, o, {transactionId: n});
    },
    SET_TRANSACTION: (o, n) => {
        return Object.assign({}, o, {transaction: n});
    }
};

export default function sdkSettingsReducer(settings = null, action) {
    if(handler[action.type]) {
        return handler[action.type](settings, action.value);
    }
    return settings;
}
